<template>
  <div>
    <action-header 
      :is-loading="!supply"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'

export default {
  name: 'Supply',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      ifPrivate: '',
      statusTypes: [
        { id: 'false', name: this.$t('Private') },
        { id: 'true', name: this.$t('Public') }
      ],
    }
  },
  computed: {
    ...mapGetters([
      'supply',
      'isAdministrator'
    ]),
    page () {
      return {
        id: this.supply && this.supply.id,
        name: this.supply && this.supply.id
          ? this.$t('Supply')
          : this.$t('New supply')
      }
    },
    headerModel () {
      if (!this.supply) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'mobile-title',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              type: 'text',
              value: this.supply.id
                ? this.$t('Supply ID: ') + this.supply.id
                : this.$t('New supply'),
              additionalValue: this.supply && `${this.$t('Type')}: ${this.$t(this.supply.type)}`
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col row text-center justify-center',
          options: [
            {
              id: 'print',
              wrapperClassName: 'q-pa-xs',
              wrapperStyle: 'max-width: 150px;',
              type: 'button',
              icon: 'qr_code',
              disabled: !this.supply || !this.supply.barcode,
              variant: 'light',
              label: this.$t('Label'),
              onClick: () => {
                this.$service.printer.generateBarcode({
                  size: '30x30',
                  barcode: this.supply.barcode,
                  forceNoAutoClose: true
                })
              }
            }
          ]
        }
      ]
    },
    schema () {
      return {
        isLoading: !this.supply,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                value: this.supply && this.supply.name,
                field: 'name',
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.updateSupply({ name })
                  this.hasChange = true
                }
              },
              // {
              //   type: 'input',
              //   inputType: 'text',
              //   label: this.$t('Code'),
              //   value: this.supply && this.supply.code,
              //   field: 'code',
              //   wrapperStyleClasses: 'col-6 q-pa-xs',
              //   onChange: code => {
              //     this.updateSupply({ code })
              //     this.hasChange = true
              //   }
              // },
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'sender',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                value: this.supply && this.supply._embedded.warehouse,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  this.updateSupplyEmbedded({ warehouse })
                  this.hasChange = true
                }
              },
              {
                if: this.supply,
                type: 'input',
                inputType: 'text',
                label: this.$t('Barcode'),
                value: this.supply && this.supply.barcode,
                field: 'barcode',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: barcode => {
                  this.updateSupply({ barcode })
                  this.hasChange = true
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Price'),
                value: this.supply && this.supply.price,
                field: 'price',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: price => {
                  this.updateSupply({ price })
                  this.hasChange = true
                }
              },
              {
                if: this.$route.params.type === 'package',
                type: 'input',
                inputType: 'number',
                label: 'X',
                field: 'x',
                value: this.supply && (this.supply.dimensions || {}).x,
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: x => {
                  this.updateSupply({ dimensions: { ...(this.supply.dimensions || {}), x } })
                  this.hasChange = true
                }
              },
              {
                if: this.$route.params.type === 'package',
                type: 'input',
                inputType: 'number',
                label: 'Y',
                value: this.supply && (this.supply.dimensions || {}).y,
                field: 'y',
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: y => {
                  this.updateSupply({ dimensions: { ...(this.supply.dimensions || {}), y } })
                  this.hasChange = true
                }
              },
              {
                if: this.$route.params.type === 'package',
                type: 'input',
                inputType: 'number',
                label: 'Z',
                value: this.supply && (this.supply.dimensions || {}).z,
                field: 'z',
                wrapperStyleClasses: 'col-4 q-pa-xs',
                onChange: z => {
                  this.updateSupply({ dimensions: { ...(this.supply.dimensions || {}), z } })
                  this.hasChange = true
                }
              },
                {
                type: 'select',
                label: this.$t('Access'),
                value: this.ifPrivate,
                field: 'type',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                customLabel (row) {
                  if(row && typeof row === 'object') {
                    return row.name
                  } else {
                      const statusTypes = [
                          { id: 'true', name: 'Private' },
                          { id: 'false', name: 'Public' }
                      ]
                      const result = statusTypes.filter(e=>e.id === row)
                      if(result.length>0) {
                          return result[0].name
                      }
                  }
                },
                options: this.statusTypes,
                onChange: (paymentState) => {
                  this.ifPrivate = paymentState.name
                  if (paymentState.id === 'false') {
                      this.updateSupply({ isPublic: 0 })
                      this.hasChange = true
                  } else if (paymentState.id === 'true') {
                      this.updateSupply({ isPublic: 1 })
                      this.hasChange = true
                  }
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: 'Priority',
                value: this.supply && this.supply.priority,
                field: 'priority',
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                rules: [
                  val => {
                    if (!/^([1-9]\d*|0)$/.test(val)) {
                      this.addErrorNotification('Please enter a positive integer');
                      return false;
                    }
                    return true;
                  }
                ],
                onChange: priority => {
                  this.updateSupply({ priority });
                  this.hasChange = true;
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.id && !this.supply) {
      this.loadSupply(this.$route.params.id)
    } else if (!this.$route.params.id) {
      this.setNewSupply(this.$route.params.type)
    }

    if(this.supply && !!this.supply.isPublic) {
        this.ifPrivate = 'Public'
      } else {
        this.ifPrivate = 'Private'
    }
  },
  unmounted () {
    this.setSupply(null)
  },
  methods: {
    ...mapActions([
      'loadSupply',
      'saveSupply'
    ]),
    ...mapMutations([
      'setSupply',
      'setNewSupply',
      'updateSupply',
      'updateSupplyEmbedded',
      'upsertSupply',
      'addErrorNotification'
    ]),
    handleChange () {
      this.hasChange = true
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    save () {
      if (!this.supply.name) {
        this.addErrorNotification('Please, fill the name!')
        return
      }
      
      if (!this.supply._embedded.warehouse) {
        this.addErrorNotification('Please, fill the warehouse!')
        return
      }

      if (!this.supply.barcode){
        this.addErrorNotification('Please, fill the barcode!')
        return
      }

      this.isSaveLoading = true
      this.saveSupply()
        .then((item) => {
          this.upsertSupply(item)
          this.hasChange = false

          if (!this.$route.params.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    }
  }
}
</script>
